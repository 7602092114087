import styled from '@emotion/styled'
import { css } from '@emotion/react'
import r from '@utils/pxToRem'
import theme from '@theme/theme'
import { under } from '@theme/media'

const getButtonVariantStyles = (variant: string) => {
  switch (variant) {
    case 'outline':
      return css`
        color: ${theme.colors.purple};
        background-color: ${theme.colors.white};
        border: 2px solid ${theme.colors.purple};

        &:hover {
          color: ${theme.colors.white};
          background-color: ${theme.colors.purple};
          border: 2px solid ${theme.colors.purple};

          ${under.tablet`
            color: ${theme.colors.purple};
            background-color: ${theme.colors.white};
          `}
        }

        &:visited {
          color: ${theme.colors.white};
        }
      `
    case 'primary':
    default:
      return css`
        color: ${theme.colors.white};
        background-color: ${theme.colors.purple};
        border: 2px solid ${theme.colors.purple};

        &:hover {
          transform: scale(1.02);
        }

        &:visited {
          color: ${theme.colors.white};
        }
      `
  }
}

const commonStyles = css`
  display: inline-block;
  font-family: ${theme.fonts.tondo};
  font-size: ${theme.fontSizes.button[1]};
  font-weight: ${theme.fontWeights.button};
  line-height: ${theme.lineHeights.button[1]};
  text-decoration: none;
  padding: ${r`16px 32px`};
  border-radius: 100px;
  transition: all 0.15s;
  cursor: pointer;
  border: none;
`

const StyledLink = styled.a<{ variant: string }>`
  ${commonStyles}
  ${props => getButtonVariantStyles(props.variant)}
`

const StyledButton = styled.button<{ variant: string }>`
  ${commonStyles}
  ${props => getButtonVariantStyles(props.variant)}
`

export { StyledLink, StyledButton }
