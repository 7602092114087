import styled from '@emotion/styled'
import { under } from '@theme/media'

const Svg = styled.svg`
  fill: ${({ theme }) => theme.colors.white};
`

const TooltipContainer = styled.span`
  display: inline-flex;
  vertical-align: middle;
  padding-bottom: 3px;
  a {
    display: inline-flex;
    vertical-align: inherit;
    cursor: pointer;
  }

  .icbc-tooltip {
    font-family: ${({ theme }) => theme.fonts.graphik};
    font-size: 14px;
    background: ${({ theme }) => theme.colors.textBlack};
    color: ${({ theme }) => theme.colors.white};
    padding: 20px 30px 19px;
    border-radius: 16px;
    max-width: 36ch;
    opacity: 1 !important;
    text-align: left;
    
    /* top: 0 !important; */
    margin: 20px !important;

    &:before,
    &:after {
      content: none;
    }

    ${under.thone`
      left: 0 !important;
    `}
  }
`

export { Svg, TooltipContainer }
