import styled from '@emotion/styled'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { MenuList, MenuItem } from '@reach/menu-button'
import { under, over } from '@theme/media'
import r from '@utils/pxToRem'

const NavContainer = styled.nav`
  flex: 1;
`

const PrimaryNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
  padding: ${r`0 20px`};
  box-sizing: border-box;
  max-width: ${({ theme }) => theme.elementSizes.maxPageWidth}px;
  margin: 0 auto;
  ${over.laptop`
    padding-bottom: ${r`20px`};
    [data-reach-menu-popover] {
      display: block;
    }
  `}

  ${({ theme }) =>
    under.laptop`
      [data-reach-menu-popover] {
        position: absolute;
        top: ${theme.elementSizes.header[1]}px;
        right: 0;
        width: 100%;
        z-index: 14;
      }
    `};
`

const SecondaryNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: ${({ theme }) => theme.elementSizes.maxPageWidth}px;
  margin: 0 auto;
  padding: ${r`16px 20px 10px`};
  box-sizing: border-box;
  ${under.laptop`
    display: none;
  `}
`

const NavList = styled(MenuList)`
  display: flex;
  flex-direction: column;
  padding: ${r`0 0 0 20px`};
  line-height: ${({ theme }) => theme.lineHeights.navigation};
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) =>
    under.laptop`
    padding: 0;
    border-top: 1px solid ${theme.colors.linkGrey};
    border-radius: 0 0 8px 8px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 12%);
    }
  `};
  
  /* &:focus-visible {
    outline: none;
  } */

  ${under.laptop`
    flex-direction: column;
    &[data-reach-menu-items] {
      animation: slide-down 0.2s ease;
    }
  `}

  ${under.phablet`
    padding: 24px 16px;
  `}


`

const PrimaryLinksContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${under.laptop`
    flex-direction: column;
    padding: ${r`0px 20px`};
  `}
`

const ExternalLinksContainer = styled.div`
  display: none;
  ${under.laptop`
    display: flex;
    flex-direction: column;
    max-height: 150px;
    flex-wrap: wrap;
    padding: ${r`20px`};
  `}
`

const NavListItem = styled(MenuItem)``

const NavListInternalLink = styled(AnchorLink as any)`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  top: 4px;
  padding: ${r`24px 16px 20px`};
  color: ${({ theme }) => theme.colors.textBlack};
  font-size: ${({ theme }) => theme.fontSizes.navigation[1]};
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;

  & > span {
    flex: 1;
  }

  &:hover {
    text-decoration: underline;
  }

  ${({ theme }) =>
    under.tablet`
    border-bottom: 1px solid ${theme.colors.mediumGrey};
  `};

  ${({ theme }) =>
    under.phablet`
    font-size: ${theme.fontSizes.navigation[1]};
    padding: ${r`16px 0 16px`};
  `};

  ${({ theme }) =>
    over.laptop`
    font-size: ${theme.fontSizes.navigation[0]};
    flex-direction: column;
  `};

  ${over.laptop`
    text-align: left;
    border-bottom: none;
    padding: ${r`0 30px`};

    &:hover {
      text-decoration: none;
    }
    
    &:hover::after {
      width: 100%;
      border-radius: 100px;
    }

    &:last-of-type {
      margin-right: 0;
      padding-right: 0;
    }

    & > svg {
      display: none;
    }
  `}

  &.active {
    margin-bottom: -4px;
    border-bottom: 4px solid ${props => props.theme.colors.blue};
    line-height: 1;
    &::after {
      content: ' ';
      display: block;
      margin: auto;
      width: 0%;
      transform: translateY(4px);
      border-bottom: 4px solid rgba(0, 0, 0, 0);
      transition: width 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  &::after {
    content: ' ';
    display: block;
    margin: auto;
    width: 0%;
    border-radius: 100px;
    transform: translateY(4px);
    border-bottom: 4px solid ${props => props.theme.colors.blue};
    transition: width 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  /* &:focus::after {
    width: 100%;
  } */
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavListExternalLink = styled.a`
  padding: ${r`24px 16px 20px`};
  color: ${({ theme }) => theme.colors.linkGrey};
  font-size: ${({ theme }) => theme.fontSizes.secondaryNavigation[1]};
  line-height: 1.5;
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 100px;

  &:hover {
    text-decoration: underline;
  }

  &:last-of-type {
    border-bottom: none;
  }

  ${({ theme }) =>
    under.phablet`
    font-size: ${theme.fontSizes.secondaryNavigation[1]};
    padding: ${r`10px 0 10px`};
  `};

  ${({ theme }) =>
    over.laptop`
    font-size: ${theme.fontSizes.secondaryNavigation[0]};
  `};

  ${over.laptop`
    text-align: left;
    border-bottom: none;
    padding: ${r`0 20px`};
    
    &:hover::after {
      width: 100%;
      border-radius: 100px;
    }

    &:last-of-type {
      margin-right: 0;
      padding-right: 0;
    }
  `}
`

export { NavContainer, PrimaryNavContainer, SecondaryNavContainer, NavList, PrimaryLinksContainer, ExternalLinksContainer, NavListItem, NavListInternalLink, NavListExternalLink }
