import styled from '@emotion/styled'
import AnchorLink from '@components/AnchorLink'
import { over } from '@theme/media'
import r from '@utils/pxToRem'

const BackToTop = styled(AnchorLink)`
  width: ${r`100%`};
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.textGrey};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.backToTop[1]};
  font-weight: ${({ theme }) => theme.fontWeights.navigation};
  padding: ${r`11px 0`};

  > span {
    padding-top: ${r`10px`};
    color: ${({ theme }) => theme.colors.blue};
  }

  ${({ theme }) =>
    over.laptop`
      font-size: ${theme.fontSizes.backToTop[0]};
      padding: ${r`6px 0`};
  `};
`

const ChevronContainer = styled.div`
  width: ${r`100%`};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

const ChevronWrapper = styled.div`
  margin: 0px 22px;
`

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  height: ${r`1px`};
  width: ${r`100%`};
`

const FooterSocials = styled.div`
  margin-bottom: ${r`0px`};
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  line-height: ${({ theme }) => theme.lineHeights.footerLink[1]};

  ${over.laptop`
    flex-direction: row;
  `}

  a {
    text-decoration: none;
    font-size: ${({ theme }) => theme.fontSizes.footerLink[1]};
    font-weight: ${({ theme }) => theme.fontWeights.footerLink};
    color: ${({ theme }) => theme.colors.blue};
    padding: ${r`8px 8px 0px 8px`};
    &:hover {
      text-decoration: underline;
    }

    ${({ theme }) =>
      over.laptop`
      padding: ${r`8px  8px  0px 8px`};
      font-size: ${theme.fontSizes.footerLink[0]};
  `};
  }
`

const FooterLinks = styled.div`
  margin-bottom: ${r`16px`};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  line-height: ${({ theme }) => theme.lineHeights.footerLink[1]};

  ${over.laptop`
    flex-direction: row;
  `}

  a {
    text-decoration: none;
    font-size: ${({ theme }) => theme.fontSizes.footerLink[1]};
    font-weight: ${({ theme }) => theme.fontWeights.footerLink};
    color: ${({ theme }) => theme.colors.blue};
    padding: ${r`8px 8px 0px 8px`};
    &:hover {
      text-decoration: underline;
    }

    ${({ theme }) =>
      over.laptop`
      padding: ${r`8px 8px 0px 8px`};
      font-size: ${theme.fontSizes.footerLink[0]};
  `};
  }
`
const Copyright = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.footerText[1]};
  color: ${({ theme }) => theme.colors.textBlack};
  text-align: center;
  ${({ theme }) =>
    over.laptop`
      font-size: ${theme.fontSizes.footerText[0]};
  `};
`

const FooterWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  margin-top: ${r`76px`};
  padding: ${r`20px`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  border-style: solid;
  border-color: ${({ theme }) => theme.colors.blue};
  border-width: 0px 0px 4px 0px;
`

const FooterContainer = styled.footer`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
`

export { BackToTop, ChevronContainer, ChevronWrapper, Divider, FooterContainer, FooterWrapper, FooterSocials, FooterLinks, Copyright }
