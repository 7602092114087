import React from 'react'
import { Link } from 'gatsby'
import Logo from '@components/Logo'
import { Menu } from '@reach/menu-button'
import { useStaticQuery, graphql } from 'gatsby'
import { NavigationMenuQuery } from 'typings/contentful-queries'
import HamburgerButton from '@components/HamburgerButton'
import {
  NavContainer,
  PrimaryNavContainer,
  SecondaryNavContainer,
  NavList,
  NavListInternalLink,
  NavListExternalLink,
  PrimaryLinksContainer,
  ExternalLinksContainer,
} from './Styles'

const Navigation: React.FC = () => {
  const onLinkClick = () => {
    document.getElementById('page-header')?.focus()
  }
  const data: NavigationMenuQuery = useStaticQuery(graphql`
    query NavigationMenu {
      contentfulHeaderNavigation(contentful_id: { eq: "1B2QBO46aCWG6seTJLMBhK" }) {
        primaryLinks {
          navigationItems {
            linkId
            destination
            text
          }
        }
        externalLinks {
          navigationItems {
            linkId
            destination
            text
          }
        }
      }
      contentfulOutagePage {
        isDisplayed
      }
    }
  `)

  const externalLinks = data?.contentfulHeaderNavigation?.externalLinks?.navigationItems ?? []
  const internalLinks = data?.contentfulHeaderNavigation?.primaryLinks?.navigationItems ?? []
  const { isDisplayed: outageIsDisplayed } = data?.contentfulOutagePage ?? {}

  return (
    <NavContainer>
      {!outageIsDisplayed && <SecondaryNavContainer>
        {externalLinks &&
          externalLinks.map(externalLink => (
            <NavListExternalLink
              href={externalLink?.destination ?? ''}
              role="menuitem"
              key={externalLink?.linkId}
            >
              <span>{externalLink?.text}</span>
            </NavListExternalLink>
          ))}
      </SecondaryNavContainer>}
      <PrimaryNavContainer>
        <Link to="/" aria-label="Home">
          <Logo />
        </Link>
        {!outageIsDisplayed && <Menu>
          <HamburgerButton />
          <NavList portal={false} id="menu--1">
            <PrimaryLinksContainer>
              {internalLinks &&
                internalLinks.map(internalLink => (
                  <NavListInternalLink
                    to={`/${internalLink?.destination ?? ''}`}
                    role="menuitem"
                    gatsbyLinkProps={{ role: 'menuitem' }}
                    onAnchorLinkClick={onLinkClick}
                    key={internalLink?.linkId}
                  >
                    <span>{internalLink?.text}</span>
                    <svg width="21px" height="21px" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <circle
                          strokeWidth="0.97px"
                          strokeMiterlimit="10"
                          stroke="#00aeef"
                          fill="none"
                          r="10"
                          cy="10.49275"
                          cx="10.49275"
                        />
                        <path
                          id="svg_2"
                          fill="#00aeef"
                          d="m8.49275,6.33275l0,0a1,1 0 0 0 0,1.4l2.78,2.76l-2.78,2.76a1,1 0 0 0 0,1.4l0,0a1,1 0 0 0 1.4,0l2.76,-2.76l0.7,-0.7a1,1 0 0 0 0,-1.4l-3.46,-3.46a1,1 0 0 0 -1.4,0z"
                        />
                      </g>
                    </svg>
                  </NavListInternalLink>
                ))}
            </PrimaryLinksContainer>
            <ExternalLinksContainer>
              {externalLinks &&
                externalLinks.map(externalLink => (
                  <NavListExternalLink
                    href={externalLink?.destination ?? ''}
                    role="menuitem"
                    key={externalLink?.linkId}
                  >
                    <span>{externalLink?.text}</span>
                  </NavListExternalLink>
                ))}
            </ExternalLinksContainer>
          </NavList>
        </Menu>}
      </PrimaryNavContainer>
    </NavContainer>
  )
}

export default Navigation
