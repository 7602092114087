import RichText from '@components/RichText'
import moment from 'moment-timezone'
import VisuallyHidden from '@reach/visually-hidden'
import React from 'react'
import {
  StyledAlert,
  IconContainer,
  AlertTitle,
  ContentContainer,
  Timestamp,
  CloseButton,
} from './Styles'

export interface AlertProps {
  className?: string
  id?: string
  type?: string
  color?: string
  data: any
  onClose: () => void
}

const Alert: React.FC<AlertProps> = ({
  className,
  id,
  color = 'red',
  type = 'expanded',
  data,
  onClose,
  ...rest
}) => {
  const { heading, body, lastUpdatedLabel, updatedAt } = data

  let userTimezone;
  try {
    userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch {}

  const formattedTime = moment(updatedAt).tz(userTimezone ?? 'America/Vancouver').format('dddd, MMM Do, h:mm a')
  
  return (
    <StyledAlert color={color} {...rest} id={id}>
      <IconContainer color={color}>
        {color === 'red' ? (
          <svg
            width="32"
            height="30"
            viewBox="0 0 32 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.17028 26.5799L14.7833 1.72076C14.9028 1.50256 15.0788 1.32052 15.2929 1.19371C15.507 1.06691 15.7512 1 16 1C16.2488 1 16.493 1.06691 16.7071 1.19371C16.9212 1.32052 17.0972 1.50256 17.2167 1.72076L30.8297 26.5799C30.9453 26.7911 31.0039 27.0288 30.9998 27.2696C30.9957 27.5104 30.9289 27.7459 30.8062 27.9531C30.6834 28.1602 30.5089 28.3319 30.2996 28.4511C30.0904 28.5704 29.8538 28.6331 29.613 28.6332H2.38701C2.1462 28.6331 1.90956 28.5704 1.70035 28.4511C1.49114 28.3319 1.31658 28.1602 1.19382 27.9531C1.07106 27.7459 1.00433 27.5104 1.0002 27.2696C0.996076 27.0288 1.05469 26.7911 1.17028 26.5799V26.5799Z"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 11.293V20.311"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 23.4297C16.092 23.4297 16.1802 23.4662 16.2453 23.5313C16.3103 23.5963 16.3468 23.6845 16.3468 23.7765"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.6533 23.7765C15.6533 23.6845 15.6899 23.5963 15.7549 23.5313C15.82 23.4662 15.9082 23.4297 16.0002 23.4297"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.0002 24.1242C15.9082 24.1242 15.82 24.0876 15.7549 24.0226C15.6899 23.9576 15.6533 23.8693 15.6533 23.7773"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.3468 23.7773C16.3468 23.8693 16.3103 23.9576 16.2453 24.0226C16.1802 24.0876 16.092 24.1242 16 24.1242"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg width="30" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30">
            <g transform="matrix(1.25,0,0,1.25,0,0)">
              <path
                stroke="white"
                strokeWidth="1.5"
                d="M12 17.25C11.7929 17.25 11.625 17.0821 11.625 16.875C11.625 16.6679 11.7929 16.5 12 16.5"
              ></path>
              <path
                stroke="white"
                strokeWidth="1.5"
                d="M12 17.25C12.2071 17.25 12.375 17.0821 12.375 16.875C12.375 16.6679 12.2071 16.5 12 16.5"
              ></path>
              <path
                stroke="white"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M12 13.5V5.25"
              ></path>
              <path
                stroke="white"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
              ></path>
            </g>
          </svg>
        )}
      </IconContainer>
      <ContentContainer type={type}>
        {type === 'expanded' && <AlertTitle>{heading}</AlertTitle>}
        <RichText data={body} />
        {type === 'expanded' && <Timestamp>{`${lastUpdatedLabel} ${formattedTime}`}</Timestamp>}
      </ContentContainer>
      <CloseButton onClick={onClose}>
        <VisuallyHidden>Close</VisuallyHidden>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L16 16"
            stroke="#020202"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 1L1 16"
            stroke="#020202"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </CloseButton>
    </StyledAlert>
  )
}

export default Alert
