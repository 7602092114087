import React from 'react'
import theme from '@theme/theme'
import { Svg, ChevronContainer } from './Styles'

interface ChevronProps {
  size?: number
  direction?: 'up' | 'down'
  color?: string
  fill?: boolean
  id: string
}

const Chevron = ({ size = 20, direction = 'down', color = theme.colors.purple, fill = false }: ChevronProps) => (
  <ChevronContainer className="chevron-icon">

    <Svg
      size={size}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="15"
        strokeWidth="2"
        stroke={color}
        fill={fill === true ? color : theme.colors.white}
      />
      <path
        transform={direction === 'up' ? 'rotate(0)' : 'rotate(180 16 16)'}
        transform-origin="16 16"
        d="M 10 17 L 16 12 L 22 17"
        stroke={fill === false ? color : theme.colors.white}
        fill={fill === true ? color : theme.colors.white}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>

  </ChevronContainer>
)

export default Chevron
