import React from 'react'
import ReactTooltip from 'react-tooltip'
import theme from '@theme/theme'
import { Svg, TooltipContainer } from './Styles'

interface TooltipProps {
  content: string
  color?: string
}

const Tooltip: React.FC<TooltipProps> = ({ content, color = theme.colors.darkPurple }) => {
  return (
    <TooltipContainer>
      <a data-tip={content} data-event="click focus">
        <Svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
        >
          <g>
            <path
              fill={color}
              d="m11.974,16.598c-0.6627,0 -0.994,-0.252 -0.994,-0.756l0,-5.754c0,-0.27067 0.0793,-0.48533 0.238,-0.644c0.1587,-0.15867 0.4153,-0.238 0.77,-0.238c0.2987,0 0.5273,0.06533 0.686,0.196c0.168,0.13067 0.252,0.33133 0.252,0.602l0,5.81c0,0.28 -0.084,0.4807 -0.252,0.602c-0.168,0.1213 -0.4013,0.182 -0.7,0.182zm0,-10.248c0.2707,0 0.518,0.084 0.742,0.252c0.2333,0.168 0.35,0.42933 0.35,0.784c0,0.31733 -0.1027,0.56933 -0.308,0.756c-0.196,0.18667 -0.4573,0.28 -0.784,0.28c-0.3267,0 -0.5927,-0.09333 -0.798,-0.28c-0.196,-0.18667 -0.294,-0.43867 -0.294,-0.756c0,-0.29867 0.098,-0.546 0.294,-0.742c0.2053,-0.196 0.4713,-0.294 0.798,-0.294z"
            />
            <ellipse stroke={color} ry="11" rx="11" cy="12" cx="12" strokeWidth="2" fill="none" />
          </g>
        </Svg>
      </a>
      <ReactTooltip
        globalEventOff="click"
        className="icbc-tooltip"
        wrapper="span"
      />
    </TooltipContainer>
  )
}

export default Tooltip
