import styled from '@emotion/styled'
import { under } from '@theme/media'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const BodyWrapper = styled.div`
  /* padding-top: ${({ theme }) => theme.elementSizes.header[0]}px;
  ${({ theme }) =>
    under.laptop`
    padding-top: ${theme.elementSizes.header[1]}px;
  `};
  @media print {
    padding-top: ${({ theme }) => theme.elementSizes.header[1]}px;
  } */
`

export { Wrapper, BodyWrapper }
