import r from '@utils/pxToRem'

export default {
  breakpoints: {
    xs: '36em',
    sm: '48em',
    md: '61.250em',
    lg: '75em',
  },
  colors: {
    blue: '#00AEEF',
    lightBlue: '#F2FBFE',
    linkBlue: '#0075C9',
    purple: '#8526FF',
    darkPurple: '#4328A9',
    lightGrey: '#F5F5F5',
    mediumGrey: '#DADADA',
    linkGrey: '#73767D',
    white: '#FFFFFF',
    textBlack: '#000000',
    textGrey: '#606060',
    red: '#F4565E',
    lightRed: '#FFBFC2',
    orange: '#FFB743',
    lightOrange: '#FFE2B3',
  },
  fonts: {
    tondo: '"Tondo", "Graphik", sans-serif',
    graphik: '"Graphik", sans-serif',
  },
  fontWeights: {
    navigation: 400,
    heading: 700,
    body: 400,
    button: 700,
    accordionHeading: 700,
    footerLink: 400,
  },
  fontSizes: {
    navigation: [r`17px`, r`16px`, r`20px`],
    secondaryNavigation: [r`14px`, r`14px`],
    heading: [r`54px`, r`32px`, r`16px`],
    headingDescription: [r`21px`, r`16px`],
    body: [r`18px`, r`18px`],
    button: [r`18px`, r`18px`],
    accordionHeading: [r`30px`, r`18px`],
    footerText: [r`13px`, r`13px`],
    footerLink: [r`14px`, r`14px`],
    backToTop: [r`13px`, r`13px`],
    questionCounter: [r`15px`, r`15px`],
    questionText: [r`24px`],
    failureHeading: [r`28px`],
    skipText: [r`16px`],
  },
  lineHeights: {
    navigation: r`24px`,
    heading: [r`54px`, r`35px`, r`24px`],
    body: [r`27px`, r`27px`],
    button: [r`24px`, r`24px`],
    accordionHeading: r`32px`,
    footerText: [r`21px`, r`21px`],
    footerLink: [r`21px`, r`21px`],
  },
  elementSizes: {
    header: [114, 87],
    maxPageWidth: 1200,
  },
  boxShadows: {
    default: '0px 4px 4px rgba(0, 0, 0, 0.06);',
  },
  transistionDurations: {
    buttonHover: 0.1,
    accordion: 0.3,
    accordionBody: 0.8,
  },
}
