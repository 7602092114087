import React from 'react'
import Navigation from '@components/Navigation'
import { HeaderWrapper, HeaderLine } from './Styles'

const Header: React.FC = () => {
  return (
    <HeaderWrapper id="page-header" tabIndex={-1}>
      <HeaderLine />
      <Navigation />
    </HeaderWrapper>
  )
}

export default Header
