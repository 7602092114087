import styled from '@emotion/styled'
import { Alert } from '@reach/alert'
import r from '@utils/pxToRem'
import theme from '@theme/theme'
import { under } from '@theme/media'

const StyledAlert = styled(Alert)<{ color: string }>`
  display: flex;
  flex-direction: row;
  background: ${props => props.color === 'red' ? theme.colors.lightRed : theme.colors.lightOrange};
`

const IconContainer = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  height: fit-content;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  padding: ${r`15px 15px 15px 20px`};
  background: ${props => props.color === 'red' ? theme.colors.red : theme.colors.orange};

  ${under.tablet`
    padding: ${r`10px 15px 10px 10px`};
  `}
`

const AlertTitle = styled.h1`
  font-family: ${theme.fonts.tondo};
  font-weight: 700;
  font-size: ${r`24px`};
  line-height: 1;
  grid-area: title;
  padding-right: ${r`35px`};

  ${under.tablet`
    font-size: ${r`18px`};
  `}
`

const ContentContainer = styled.div<{ type: string }>`
  display: grid;
  grid-template:
    "title body" auto
    "..... time" auto / auto 1fr;
  flex: 1;
  font-family: ${theme.fonts.graphik};
  margin: ${r`20px 0px 20px 20px`};
  padding-right:  ${r`20px`};
  border-right: 1px solid ${theme.colors.linkGrey};

  ${under.tablet`
    margin: ${r`15px 0px 20px 15px`};
    border-right: none;
    grid-template:
    "title" auto
    "body " auto
    "time " auto / 1fr;
  `}

  .rich-text {
    padding-top: ${props => props.type === 'expanded' ? r`2px` : '0px'};
    grid-area: body;

    ${({ type }) =>
      under.tablet`
        padding-top: ${type === 'expanded' ? r`15px` : '0px'};
    `};
  }

  h2 {
    font-weight: 600;
    font-size: ${r`18px`};
    line-height: ${r`24px`};
    margin-bottom: ${r`15px`};

    ${under.tablet`
      font-size: ${r`16px`};
      margin-bottom: ${r`0px`};
    `}
  }

  p {
    font-size: ${r`16px`};
    line-height: ${r`24px`};
    margin-bottom: ${r`8px`};
    max-width: 94ch;

    b {
      font-weight: 700;
    }

    a {
      color: ${theme.colors.linkBlue};
      &:hover {
        color: ${theme.colors.purple};
      }
    }
  }
`

const Timestamp = styled.span`
  grid-area: time;
  font-family: ${theme.fonts.graphik};
  font-size: ${r`14px`};
  line-height: 1;
  display: inline-block;
  padding: ${r`8px 0 0`};
`

const CloseButton = styled.button`
  display: flex;
  height: fit-content;
  background: transparent;
  border: none;
  margin-top: 3px;
  padding: ${r`20px`};
  cursor: pointer;
`

export { StyledAlert, IconContainer, AlertTitle, ContentContainer, Timestamp, CloseButton }
