import React from 'react'
import theme from '@theme/theme'
import Chevron from '@components/Chevron'
import { useStaticQuery, graphql } from 'gatsby'
import { FooterQuery } from 'typings/contentful-queries'
import {
  BackToTop,
  ChevronContainer,
  ChevronWrapper,
  Divider,
  FooterContainer,
  FooterWrapper,
  FooterSocials,
  FooterLinks,
  Copyright,
} from './Styles'

const Footer: React.FC = () => {
  const data: FooterQuery = useStaticQuery(graphql`
    query Footer {
      contentfulFooter {
        backToTop
        socials {
          link
          icon {
            file {
              url
            }
            description
          }
          name
        }
        textLinks {
          text
          linkId
          destination
        }
        copyrightText
      }
    }
  `)

  // const data = {
  //   contentfulFooter: {
  //     backToTop: 'Back to top',
  //     footerSocials: [
  //       {
  //         destination: 'https://www.facebook.com/theICBC',
  //         displayIcon:
  //           'https://www.icbc.com/_layouts/15/ICBC.Internet.Branding/Images/icon-social-facebook.svg',
  //         title: 'facebook',
  //         contentfulid: '123',
  //       },
  //       {
  //         destination: 'https://twitter.com/icbc/',
  //         displayIcon:
  //           'https://www.icbc.com/_layouts/15/ICBC.Internet.Branding/Images/icon-social-twitter.svg',
  //         title: 'twitter',
  //         contentfulid: '456',
  //       },
  //       {
  //         destination: 'http://www.youtube.com/icbc',
  //         displayIcon:
  //           'https://www.icbc.com/_layouts/15/ICBC.Internet.Branding/Images/icon-social-youtube.svg',
  //         title: 'youtube',
  //         contentfulid: '456',
  //       },
  //       {
  //         destination: 'http://www.linkedin.com/company/icbc',
  //         displayIcon:
  //           'https://www.icbc.com/_layouts/15/ICBC.Internet.Branding/Images/icon-social-linkedin.svg',
  //         title: 'linkedin',
  //         contentfulid: '456',
  //       },
  //     ],
  //     textLinks: [
  //       // {
  //       //   destination: '/',
  //       //   displayText: 'Link one',
  //       //   contentfulid: '123',
  //       // },
  //       // {
  //       //   destination: '/',
  //       //   displayText: 'Link two',
  //       //   contentfulid: '456',
  //       // },
  //       // {
  //       //   destination: '/',
  //       //   displayText: 'Link three',
  //       //   contentfulid: '789',
  //       // },
  //     ],
  //     copyrightText: 'All rights reserved.',
  //   },
  // }

  const { backToTop, socials, textLinks, copyrightText } = data.contentfulFooter || {}
  const currentYear = new Date().getFullYear().toString()

  return (
    <FooterContainer>
      <FooterWrapper>
        <BackToTop title={backToTop ?? 'Back to top'} id="back-to-top-button" href="#">
          <ChevronContainer>
            <Divider />
            <ChevronWrapper>
              <Chevron id="back-to-top-icon" direction="up" color={theme.colors.blue} />
            </ChevronWrapper>
            <Divider />
          </ChevronContainer>
          <span>{backToTop}</span>
        </BackToTop>
        <FooterSocials>
          {socials?.map(socialItem => (
            <a
              key={socialItem?.name ?? ''}
              title={socialItem?.name ?? ''}
              id={socialItem?.name ?? ''}
              href={socialItem?.link ?? ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={socialItem?.icon?.file?.url ?? ''}
                alt={socialItem?.icon?.description ?? ''}
              />
            </a>
          ))}
        </FooterSocials>
        <FooterLinks>
          {textLinks?.map(linkItem => (
            <a
              key={linkItem?.linkId ?? ''}
              title={linkItem?.text ?? ''}
              id={linkItem?.linkId ?? ''}
              href={linkItem?.destination ?? ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkItem?.text}
            </a>
          ))}
        </FooterLinks>
        <Copyright>{copyrightText?.replace('YYYY', currentYear)}</Copyright>
      </FooterWrapper>
    </FooterContainer>
  )
}

export default Footer
