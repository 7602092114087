import styled from '@emotion/styled'
import { MenuButton } from '@reach/menu-button'
import { over } from '@theme/media'

const HamburgerIconLine = styled.span`
  border-radius: 1000px;
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
`

const HamburgerIcon = styled.div`
  width: 16px;
  height: 10px;
  position: relative;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  ${HamburgerIconLine}:nth-of-type(1) {
    top: 0px;
    transform-origin: left center;
    transform: rotate(0);
    left: 0px;
  }

  ${HamburgerIconLine}:nth-of-type(2) {
    top: 4px;
    transform-origin: left center;
    width: 100%;
    opacity: 1;
  }

  ${HamburgerIconLine}:nth-of-type(3) {
    top: 8px;
    transform-origin: left center;
    transform: rotate(0);
    left: 0px;
  }
`

const HamburgerButtonContainer = styled(MenuButton)`
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 0px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  height: 38px;
  width: 38px;
  border-radius: 1000px;
  border-style: none;
  background-color: ${({ theme }) => theme.colors.purple};

  ${over.laptop`
    display: none;
  `}

  &[data-reach-menu-button][aria-expanded='true'] {
    ${HamburgerIcon} {
      ${HamburgerIconLine}:nth-of-type(1) {
        top: 4px;
        transform-origin: center;
        transform: rotate(45deg);
        left: 0px;
      }

      ${HamburgerIconLine}:nth-of-type(2) {
        top: 4px;
        transform-origin: center;
        width: 0%;
        opacity: 0;
      }

      ${HamburgerIconLine}:nth-of-type(3) {
        top: 4px;
        transform-origin: center;
        transform: rotate(-45deg);
        left: 0px;
      }
    }
  }
`

export { HamburgerButtonContainer, HamburgerIcon, HamburgerIconLine }
