import { css } from '@emotion/react'
import r from '@utils/pxToRem'
import theme from './theme'

const reset = css`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
    font-family: 'Graphik', sans-serif;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`

const imports = css`
  @font-face {
    font-family: 'Tondo';
    src: url('/fonts/Tondo/TondoBold.woff');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik/GraphikRegular.otf');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik/GraphikSemibold.otf');
    font-weight: bold;
  }
`

const preferences = css`
  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  :root {
    --reach-menu-button: 1;
  }

  body {
    font-family: 'Graphik', sans-serif;
  }

  main {
    color: ${theme.colors.textBlack};

    p {
      font-size: ${theme.fontSizes.body[0]};
      line-height: 1.5;
      margin-bottom: ${r`8px`};
      white-space: pre-wrap;

      a {
        color: ${theme.colors.linkBlue};
        text-decoration: none;
        &:hover {
          color: ${theme.colors.purple};
          text-decoration: underline;
        }
        &:visited {
          color: ${theme.colors.darkPurple};
          &:hover {
            color: ${theme.colors.purple};
            text-decoration: underline;
          }
        }
      }
    }

    h3 {
      color: ${theme.colors.textBlack};
      line-height: 1.5;
      font-weight: ${theme.fontWeights.heading};
      margin-bottom: ${r`8px`};
    }

    ul {
      list-style: disc;
      padding-left: ${r`32px`};
    }
  }
`

const globals = css`
  ${imports}
  ${reset}
  ${preferences}
`

export default globals
