import styled from '@emotion/styled'
import { under, over } from '@theme/media'

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  width: 100%;
  z-index: 15;
  height: ${({ theme }) => theme.elementSizes.header[0]}px;

  ${({ theme }) =>
    under.laptop`
      height: ${theme.elementSizes.header[1]}px;
  `};
`

const HeaderLine = styled.div`
  ${({ theme }) =>
    over.laptop`
      position: absolute;
      top: 0;
      content: ' ';
      display: block;
      margin: auto;
      height: 4px;
      background: ${theme.colors.blue};
      width: 100%;
  `};
`

export { HeaderWrapper, HeaderLine }
