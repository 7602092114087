/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
import React from 'react'
import { useAtom } from 'jotai'
import { changeSourceAtom } from '../../state'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import Button from '@components/Button'
import Tooltip from '@components/Tooltip'

export interface RichTextProps {
  data: any
  options?: Options
}

export interface HyperlinkNode {
  content: { value?: string }[]
  data: { uri: string }
}

const defaultOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      const { destination, text, linkId } = node.data.target
      return <Button href={destination} id={linkId} label={text} />
    },

    [INLINES.HYPERLINK]: (node: any) => {
      if (node.data.uri.startsWith('#')) {
        const [, setChangeSource] = useAtom(changeSourceAtom)
        return <a href={node.data.uri} onClick={() => setChangeSource('link')}>{node.content[0].value}</a>
      }
      return (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {node.content[0].value}
        </a>
      )
    },

    [INLINES.EMBEDDED_ENTRY]: (node: any) => {
      const tooltipBody = node?.data?.target?.tooltipBody?.tooltipBody ?? ''
      if (node?.data?.target?.__typename === 'ContentfulTooltip')
        return <Tooltip content={tooltipBody} />
      return <></>
    },
  },
}

const RichText: React.FC<RichTextProps> = ({ data, options = defaultOptions }) => {
  return <div className="rich-text">{renderRichText(data, options)}</div>
}

export default RichText
