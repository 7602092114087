import React, { MouseEventHandler } from 'react'
import { StyledLink, StyledButton } from './Styles'

export interface ButtonProps {
  label?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  href?: string
  className?: string
  id?: string
  variant?: string
}

const Button: React.FC<ButtonProps> = ({
  label,
  href,
  onClick,
  className,
  id,
  variant = 'primary',
  children,
  ...rest
}) => {
  if (href) {
    return (
      <StyledLink variant={variant} href={href} target="_blank" rel="noreferrer" {...rest} id={id}>
        {label || children}
      </StyledLink>
    )
  }
  return (
    <StyledButton variant={variant} type="button" onClick={onClick} {...rest} id={id}>
      {label || children}
    </StyledButton>
  )
}

export default Button
