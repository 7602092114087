import * as React from 'react'
import Helmet from 'react-helmet'
import { Global, ThemeProvider } from '@emotion/react'
import { useStaticQuery, graphql } from 'gatsby'
import { AlertQuery } from 'typings/contentful-queries'
import useLocalStorage from 'use-local-storage'
import globals from '@theme/globals'
import theme from '@theme/theme'
import Header from '@components/Header'
import Footer from '@components/Footer'
import Alert from '@components/Alert'

import { BodyWrapper } from './Styles'

export interface LayoutProps {
  lang: string
}

const Layout: React.FC<LayoutProps> = ({ children, lang }) => {
  const data: AlertQuery = useStaticQuery(graphql`
    query Alert {
      contentfulAlert {
        isDisplayed
        color
        type
        updatedAt
        lastUpdatedLabel
        heading
        body {
          raw
        }
      }
    }
  `)
  const { color, type, isDisplayed, ...rest } = data?.contentfulAlert ?? {}
  const [isDismissed, setIsDismissed] = useLocalStorage(data?.contentfulAlert?.updatedAt, !isDisplayed)

  const onClose = () => {
    setIsDismissed(true)
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <Global styles={globals} />
        <Helmet>
          <html lang={lang} />
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        </Helmet>
        {!isDismissed && <Alert color={color?.toLowerCase()} type={type?.toLowerCase()} data={rest} onClose={onClose} />}
        <Header />
        <BodyWrapper>{children}</BodyWrapper>
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default Layout
