import React from 'react'
import { HamburgerButtonContainer, HamburgerIcon, HamburgerIconLine } from './Styles'

const HamburgerButton: React.FC = () => {
  return (
    <HamburgerButtonContainer aria-label="Open menu">
      <HamburgerIcon>
        <HamburgerIconLine />
        <HamburgerIconLine />
        <HamburgerIconLine />
      </HamburgerIcon>
    </HamburgerButtonContainer>
  )
}

export default HamburgerButton
